import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types" // Removed INLINES since it's unused

// Helper: Slugify a string
const slugify = text =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')

const PlanTools = ({ data }) => {
  const { heading, listHeading, listItems, listContent } = data.contentfulPlanTools

  const [idx, setIdx] = useState(0)
  const [next, setNext] = useState(listItems[1]?.label)
  const [prev, setPrev] = useState("")

  // On mount, check for URL hash or ?tab= query parameter and set the active tab
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const queryTab = params.get("tab")
    const hashTab = window.location.hash.replace("#", "")
    const activeSlug = queryTab || hashTab

    if (activeSlug) {
      const foundIndex = listItems.findIndex(
        item => slugify(item.label) === activeSlug
      )
      if (foundIndex !== -1) {
        setIdx(foundIndex)
        if (foundIndex < listItems.length - 1) {
          setNext(listItems[foundIndex + 1].label)
        } else {
          setNext("")
        }
        if (foundIndex > 0) {
          setPrev(listItems[foundIndex - 1].label)
        } else {
          setPrev("")
        }
      }
    }
  }, [listItems])

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return (
          <img
            src={node.data.target.url}
            alt={node.data.target.title}
            style={{ maxWidth: "100%" }}
          />
        )
      },
      // If you want hyperlinks to open in a new tab if external:
      // [INLINES.HYPERLINK]: node => { ... }
    }
  }

  return (
    <div className="PlanToolsPage">
      <Layout>
        <Seo title="Regulatory Guidelines - Measurement" />
        <div className="main_content_wrapper measurement_page">
          <div className="measurement_main_section">
            <div className="container">
              <div className="measurement_block" id="topSec">
                <div className="measurement_left_col">
                  <div className="measurement_sidebar_block" id="sidebar">
                    <div className="measurement_sidebar_content">
                      <div className="measurement_common_sidebar_div">
                        <div className="measurement_common_title_box active">
                          <h3>{listHeading}</h3>
                        </div>
                      </div>
                      <div className="measurement_common_sidebar_div">
                        <div className="measurement_common_list_ul">
                          <ul className="tabs">
                            {listItems.map((item, i) => (
                              <li
                                key={item.id}
                                // a11y fix: you might consider turning this <li> into a <button> or <Link>
                                role="button"
                                tabIndex={0}
                                onClick={() => {
                                  setIdx(i)
                                  if (i < listItems.length - 1) {
                                    setNext(listItems[i + 1].label)
                                  } else {
                                    setNext("")
                                  }
                                  if (i > 0) {
                                    setPrev(listItems[i - 1].label)
                                  } else {
                                    setPrev("")
                                  }
                                }}
                                onKeyDown={e => {
                                  if (e.key === "Enter") {
                                    setIdx(i)
                                  }
                                }}
                                className={`tab-link ${idx === i ? "active" : ""}`}
                              >
                                <Link
                                  to={`#${slugify(item.label)}`}
                                  className="common_tabs_title"
                                >
                                  {item.label}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="measurement_right_col resources_right_col cm_right_content">
                  <div className="planning_tools_right_content">
                    <div className="measurement_info_content_block">
                      <div className="measurement_info_title">
                        <h1>{heading}</h1>
                      </div>

                      <div className="tabs_data">
                        {listContent.map((content, i) => (
                          i === idx && (
                            <div
                              className="tab-content active"
                              id={`tab-${i}`}
                              key={content.id}
                            >
                              <div className="commb_data_report">
                                <p className="#0">{content.heading}</p>
                              </div>
                              <div className="measurement-richtext">
                                {renderRichText(content.content, options)}
                              </div>
                            </div>
                          )
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="planning_navigator">
                    {/* Previous */}
                    {listItems.map((item, i) => {
                      if (item.label === prev && idx > 0) {
                        return (
                          <a
                            href="#topSec"
                            key={item.id}
                            onClick={() => {
                              setIdx(i)
                              setPrev(listItems[i - 1]?.label || "")
                              setNext(listItems[i + 1]?.label || "")
                            }}
                          >
                            <div className="planning_left_arrow">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="4.842"
                                height="8.071"
                                viewBox="0 0 4.842 8.071"
                              >
                                <g
                                  id="right-arrow"
                                  transform="translate(-1226.939 -692.432)"
                                >
                                  <path
                                    id="Icon_open-arrow-left"
                                    data-name="Icon open-arrow-left"
                                    d="M3.561,0,0,3,3.561,5.935V0Z"
                                    transform="translate(1231 699.427) rotate(180)"
                                    fill="#f8f8f8"
                                  ></path>
                                  <path
                                    id="Icon_open-arrow-left_-_Outline"
                                    data-name="Icon open-arrow-left - Outline"
                                    d="M4.061-1.076V6.995L-.781,3.008Zm-1,3.95v-1.8L.781,3l2.28,1.877v-2Z"
                                    transform="translate(1231 699.427) rotate(180)"
                                    fill="#f8f8f8"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            {prev}
                          </a>
                        )
                      }
                      return null
                    })}

                    {/* Next */}
                    {listItems.map((item, i) => {
                      if (item.label === next && listItems.length - 1 !== idx) {
                        return (
                          <a
                            href="#topSec"
                            key={item.id}
                            onClick={() => {
                              setIdx(i)
                              setNext(listItems[i + 1]?.label || "")
                              setPrev(listItems[i - 1]?.label || "")
                            }}
                            className="nextItem"
                          >
                            {next}
                            <div className="planning_right_arrow">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="4.842"
                                height="8.071"
                                viewBox="0 0 4.842 8.071"
                              >
                                <g
                                  id="right-arrow"
                                  transform="translate(-1226.939 -692.432)"
                                >
                                  <path
                                    id="Icon_open-arrow-left"
                                    data-name="Icon open-arrow-left"
                                    d="M3.561,0,0,3,3.561,5.935V0Z"
                                    transform="translate(1231 699.427) rotate(180)"
                                    fill="#f8f8f8"
                                  ></path>
                                  <path
                                    id="Icon_open-arrow-left_-_Outline"
                                    data-name="Icon open-arrow-left - Outline"
                                    d="M4.061-1.076V6.995L-.781,3.008Zm-1,3.95v-1.8L.781,3l2.28,1.877v-2Z"
                                    transform="translate(1231 699.427) rotate(180)"
                                    fill="#f8f8f8"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                          </a>
                        )
                      }
                      return null
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default PlanTools

// Only default export of React component + named export for query
export const query = graphql`
  query PlanTools($language: String) {
    contentfulPlanTools(node_locale: { eq: $language }) {
      heading
      listHeading
      listItems {
        id
        label
      }
      listContent {
        id
        heading
        content {
          raw
          # references removed if your Contentful model doesn't support them
        }
      }
    }
  }
`
